<template lang="html">
  <div class="" id="monitorea">
    <div class="">
      <div class="row ">
        <h1 class="ocultar favoritos-resumen">
          <svg class="heart">
            <path id="Vector"
              d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z"
              transform="translate(2.75 4.263)" />
          </svg>Favoritos
        </h1>
        <div class="regresa">
          <div class="excel">
            <button class="btn btn-exportar mt-4 regresar" style="padding:0px" @click="regresarAcotizacion()">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.334 11.675L12.148 14.861L15.334 18.047" stroke="#26558F" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.892 14.861L12.234 14.861" stroke="#26558F" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path opacity="0.4"
                  d="M14.9349 24.892C9.43494 24.892 4.97794 21.158 4.97794 14.935C4.97794 8.71201 9.43493 4.97801 14.9349 4.97801"
                  stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg><strong>Regresar</strong>
            </button>
          </div>
        </div>
      </div>
      <div class="">
        <div class="card-resumen">
          <div class="resumen-titulo">Resumen de crédito</div>
          <div class="resumen-valor">
            <div class=" ">
              <a v-if="PrecalificacionTipo == 3"> Valor del vehículo </a>
              <a v-else> Valor Inmueble: </a>
              <span class="monto-valor">$ {{caso.ValorInmueble}}</span></div>
          </div>
          <div
            v-if="caso.Destino == 1 || caso.Destino == 24 || caso.Destino == 21 || caso.Destino == 3 || caso.Destino == 27 || caso.Destino == 15 || caso.Destino == 8 || caso.Destino == 10 || caso.Destino == 32 || caso.Destino == 5 || caso.Destino == 20 || caso.Destino == 2 || PrecalificacionTipo == 3">
            <div class="resumen-monto">
              <div class="  ">Monto de crédito: <span class="monto-valor">$ {{caso.MontoSolicitado}}</span></div>
            </div>
          </div>

          <div
            v-if="caso.Destino == 19 || caso.Destino == 25 || caso.Destino == 22 || caso.Destino == 33 || caso.Destino == 34">
            <div class="resumen-monto">
              <div class="  ">Saldo Insoluto: <span class="monto-valor">$ {{caso.SaldoInsoluto}}</span></div>
            </div>
            </br>
            <div class="resumen-monto">
              <div class="  ">Pago mensual actual: <span class="monto-valor">$ {{caso.PagoMensualActual}}</span></div>
            </div>
            </br>
            <div class="resumen-monto">
              <div class="  ">Plazo restante: <span class="monto-valor"> {{caso.PlazoRestante}}</span></div>
            </div>
          </div>

          <div id="botonPrint" class="col-md-12 d-md-flex justify-content-md-end ">
            <button style="background: #079DEF !important; color:#FFF; margin-right:20px;" class="btn "
              icon-pack="feather" icon="icon-printer" @click="descargarPDF({favoritos})"
              v-if="$store.state.AppActiveUser.TipoEjecutivoV3!=='Inmo' && nuevaCotizacion==false">Descargar PDF</button>
            <button style="background: #079DEF !important; color:#FFF; margin-right:20px;" class="btn "
              icon-pack="feather" icon="icon-printer" @click="descargarPDF({favoritos})"
              v-if="$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo' ">Descargar PDF</button>
            <button style="background: #079DEF !important; color:#FFF; margin-right:20px;" class="btn "
              icon-pack="feather" icon="icon-mail" @click="sendEmail"
              v-if="$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo' ">Enviar por
              correo</button>
            <button style="background: #079DEF !important; color:#FFF; margin-right:20px;" class="btn "
              icon-pack="feather" icon="icon-mail" @click="sendEmail"
              v-if="$store.state.AppActiveUser.TipoEjecutivoV3!=='Inmo' && nuevaCotizacion==false">Enviar por
              correo</button>
          </div>
        </div>
      </div>

      <CasoCompletoFavCustomCols 
        class="d-print-none"
        v-on:colsSelected="updateTable( $event )" 
        />

      <vs-table :data="favoritos" class="tabla-resumen">
        <template slot="thead">
          <vs-th v-if="searchColTable('Logo')" sort-key="Logo"></vs-th>
          
          <!-- <vs-th v-if="searchColTable('Banco')" sort-key="Banco"><span style="font-size:11.5px;">Banco</span></vs-th> -->
          <!-- cambio por mejora -->
          <vs-th v-if="searchColTable('Productos')" sort-key="Banco"><span style="font-size:11.5px;">Productos</span></vs-th>

          <vs-th v-if="searchColTable('Mensualidad Inicial')" sort-key="Mensualidad"><span style="font-size:11.5px;">Mensualidad Inicial</span></vs-th>
          <vs-th v-if="searchColTable('Plazo')" sort-key="Plazo"><span style="font-size:11.5px;">Plazo</span></vs-th>
          <vs-th v-if="searchColTable('Pago total')" sort-key="PagoTotal"><span style="font-size:11.5px;">Pago Total</span></vs-th>
          <vs-th v-if="searchColTable('Tasa')" sort-key="Tasa"><span style="font-size:11.5px;">Tasa</span></vs-th>
          <vs-th v-if="searchColTable('CAT')" sort-key="Cat"><span style="font-size:11.5px;">CAT</span></vs-th>
          <vs-th v-if="searchColTable('Ingresos Requeridos')" sort-key="IngresoMinimo"><span style="font-size:11.5px;">Ingresos Requeridos</span></vs-th>
          <vs-th v-if="searchColTable('Gastos Iniciales')" sort-key="GastosIniciales"><span style="font-size:11.5px;">Gastos Iniciales</span></vs-th>
          <vs-th 
            v-if="(caso.destino == 19 || caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34)
            && searchColTable('Ahorro Mensual')
            ">
            <span style="font-size:11.5px;">Ahorro mensual</span>
          </vs-th>
          <vs-th 
            v-if="(caso.destino == 19 || caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34)
            && searchColTable('Ahorro Total')
            ">
            <span style="font-size:11.5px;">Ahorro total</span>
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td v-if="searchColTable('Logo')" :data="data[indextr].Logo">
              <div class="bankName">
                {{ data[indextr].Banco }}
              </div>
              <img :src="'https://cotizador.socasesores.com/uploads/'+ data[indextr].Logo"
                :alt="data[indextr].Logo" class="logo">
            </vs-td>

            <!-- <vs-td v-if="searchColTable('Banco')" :data="data[indextr].Producto" class="">
              <p style="font-size:10px;color:black" class="producto">{{ data[indextr].Producto }}</p>
            </vs-td> -->
            <!-- cambio por mejora -->
            <vs-td v-if="searchColTable('Productos')" :data="data[indextr].Producto" class="">
              <p style="font-size:10px;color:black" class="producto">{{ data[indextr].Producto }}</p>
            </vs-td>

            <vs-td v-if="searchColTable('Mensualidad Inicial')" :data="data[indextr].Mensualidad" class="font-imp">
              <span style="font-weight:600; font-size:11.5px;" v-if="data[indextr].MejarMensualidad">${{
                data[indextr].Mensualidad }}</span>
              <span style="font-size:11.5px;" v-else="!data[indextr].MejarMensualidad">${{ data[indextr].Mensualidad
                }}</span>
            </vs-td>
            <vs-td v-if="searchColTable('Plazo')" :data="data[indextr].Plazo" class="font-imp">
              <span v-if="PrecalificacionTipo == 3" style="font-size:11.5px;">{{ data[indextr].Plazo }} Meses</span>
              <span v-else style="font-size:11.5px;">{{ data[indextr].Plazo/ 12 }} Años</span>
            </vs-td>
            <vs-td v-if="searchColTable('Pago total')" :data="data[indextr].PagoTotal" class="font-imp">
              <!-- ${{ data[indextr].PagoTotal }}<feather-icon icon="CheckIcon" v-if="data[indextr].MejorPagoTotal"/>-->
              <span style="font-weight:600; font-size:11.5px;" v-if="data[indextr].MejorPagoTotal">${{
                data[indextr].PagoTotal }}</span>
              <span style="font-size:11.5px;" v-else="!data[indextr].MejorPagoTotal">${{ data[indextr].PagoTotal
                }}</span>
            </vs-td>
            <vs-td v-if="searchColTable('Tasa')" :data="data[indextr].Tasa" class="font-imp">
              <!--{{ data[indextr].Tasa }}%<feather-icon icon="CheckIcon" v-if="data[indextr].MejorTasa"/>-->
              <span style="font-weight:600;font-size:11.5px;" v-if="data[indextr].MejorTasa">{{
                data[indextr].Tasa}}%</span>
              <span style="font-size:11.5px;" v-else="!data[indextr].MejorTasa">{{ data[indextr].Tasa }}%</span>
            </vs-td>
            <vs-td v-if="searchColTable('CAT')" :data="data[indextr].Cat" class="font-imp">
              <!--{{ data[indextr].Cat }}%<feather-icon icon="CheckIcon" v-if="data[indextr].MejorCat"/>-->
              <span style="font-weight:600;font-size:11.5px;" v-if="data[indextr].MejorCat">{{ data[indextr].Cat
                }}%</span>
              <span style="font-size:11.5px;" v-else="!data[indextr].MejorCat">{{ data[indextr].Cat }}%</span>
            </vs-td>
            <vs-td v-if="searchColTable('Ingresos Requeridos')" :data="data[indextr].IngresoMinimo" class="font-imp">
              <!--${{ data[indextr].IngresoMinimo }}<feather-icon icon="CheckIcon" v-if="data[indextr].MejorIngresoMinimo"/>-->
              <span style="font-weight:600;font-size:11.5px;" v-if="data[indextr].MejorIngresoMinimo">${{
                data[indextr].IngresoMinimo }}</span>
              <span style="font-size:11.5px;" v-else="!data[indextr].MejorIngresoMinimo">${{ data[indextr].IngresoMinimo
                }}</span>
            </vs-td>
            <vs-td v-if="searchColTable('Gastos Iniciales')" :data="data[indextr].GastosIniciales" class="font-imp">
              <!--${{ data[indextr].GastosIniciales }}<feather-icon icon="CheckIcon" v-if="data[indextr].MejorGastosIniciales"/>-->
              <span style="font-weight:600; font-size:11.5px;" v-if="data[indextr].MejorGastosIniciales">${{
                data[indextr].GastosIniciales }}</span>
              <span style="font-size:11.5px;" v-else="!data[indextr].MejorGastosIniciales">${{
                data[indextr].GastosIniciales }}</span>
            </vs-td>

            <vs-td :data="data[indextr].MejorMensualidad"
              v-if="(caso.destino == 19 || caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34)
              && searchColTable('Ahorro Mensual')"
              class="font-imp">
              <span style="font-weight:600; font-size:11.5px;" v-if="!data[indextr].MejorMeMensualidad">${{
                data[indextr].MejorMensualidad > 0 ? numberFormat(data[indextr].MejorMensualidad):0 }}</span>
              <span style="font-size:11.5px;" v-else="data[indextr].MejorMeMensualidad">${{
                data[indextr].MejorMensualidad
                > 0 ? numberFormat(data[indextr].MejorMensualidad):0 }}</span>
            </vs-td>

            <vs-td :data="data[indextr].AhorroTotal"
              v-if="(caso.destino == 19 || caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34)
              && searchColTable('Ahorro Total')"
              class="font-imp">
              <span style="font-weight:600; font-size:11.5px;" v-if="!data[indextr].MejorAhorroTotal">${{
                data[indextr].AhorroTotal > 0 ? numberFormat(data[indextr].AhorroTotal):0 }}</span>
              <span style="font-size:11.5px;" v-else="data[indextr].MejorAhorroTotal">${{ data[indextr].AhorroTotal > 0
                ?
                numberFormat(data[indextr].AhorroTotal):0 }}</span>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
      <p>*Recuerda que estos resultados se calculan con base a los valores que nos indicas y datos que son
        proporcionados por la entidad financiera. Todos los datos están sujetos a validación y su correcta aprobación
      </p>
    </div>

    <div v-if="mostrarInfoSolicitante">
      <vx-card title="">
        <h4 class="titulo">Opciones de crédito</h4>
        <div class="row w-full">
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <button class="btn btn-primary" @click="regresar()">Regresar</button>
            <button class="btn btn-secondary" @click="registrarSolicitante()" :disabled="!validar">Terminar</button>
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-3">
            <label class="span-placeholder">Nombre</label>
            <input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="nombre"
              name="nombres" />
            <!-- <span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido, solo caracteres</span>-->
          </div>
          <div class="col-md-3">
            <label class="span-placeholder">Segundo nombre</label>
            <input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="segundoNombre"
              name="segundoNombre" />
            <!--<span class="text-danger text-sm" v-show="errors.has('segundoNombre')">Campo requerido, solo caracteres</span>-->
          </div>
          <div class="col-md-3">
            <label class="span-placeholder"> Apellido Paterno</label>
            <input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="apellidoPaterno"
              name="apellidoPaterno" />
            <!--<span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido, solo caracteres</span>   -->
          </div>
          <div class="col-md-3">
            <label class="span-placeholder"> Apellido Materno</label>
            <input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="apellidoMaterno"
              name="apellidoMaterno" />
            <!--<span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido, solo caracteres</span>-->
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-3">
            <label class="span-placeholder">Celular (10 dígitos)</label>
            <input class="vs-input" v-model="telefono" name="telefono" maxlength="10"
              v-validate="'numeric|required|min:10|max:10'" v-on:keypress="isNumber($event)" />
            <!--<span class="text-danger text-sm" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span> -->
          </div>
          <div class="col-md-3">
            <label class="span-placeholder">Correo electrónico</label>
            <input class="vs-input" v-model="email" v-validate="'required|email'" name="email" />
            <!--<span class="text-danger text-sm" v-show="errors.has('email')">Campo requerido, debe ser un email válido</span>     -->
          </div>
        </div>
      </vx-card>
    </div>
    <div class="modal fade " id="modalTabla" ref="table" size="sm" hide-footer hide-header>
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="d-md-flex justify-content-md-center">
                <h1 style="color: #00368C;">{{mensajeModal}}</h1>
              </div>
              <div class="d-md-flex justify-content-md-center" id="icono"></div>
            </div>
            <div class="row">
              <div class="d-md-flex justify-content-md-center">
                <h1 style="color: #00368C;">Folio de simulación</h1>
              </div>
            </div>
            <div class="row">
              <div class="d-md-flex justify-content-md-center">
                <h1 style="color: #00368C;">{{id}}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import TheNavbarVerticalPerfil from '@/layouts/components/navbar/TheNavbarVerticalPerfil.vue'

  import axios from 'axios';
  export default {
    components: {
      TheNavbarVerticalPerfil,
      CasoCompletoFavCustomCols: defineAsyncComponent(() => import('@/components/customcols/CasoCompletoFavCustomCols.vue'))
    },
    data() {
      return {
        factorPorcentualSantander: {
				aforoTipoA: [ // 5-50% 
					{ plazo: 10, porcentaje: 1.1706 },
					{ plazo: 15, porcentaje: 1.6414 },
					{ plazo: 20, porcentaje: 2.0394 },
				],
				aforoTipoB: [ // 55-70% 
					{ plazo: 10, porcentaje: 1.3439 },
					{ plazo: 15, porcentaje: 1.8819 },
					{ plazo: 20, porcentaje: 2.3396 },
				],
				aforoTipoC: [ // 75%  
					{ plazo: 10, porcentaje: 1.5161 },
					{ plazo: 15, porcentaje: 2.1227 },
					{ plazo: 20, porcentaje: 2.6401 },
				],
				aforoTipoD: [ // 80% 
					{ plazo: 10, porcentaje: 1.4747 },
					{ plazo: 15, porcentaje: 2.0654 },
					{ plazo: 20, porcentaje: 2.5683 },
				],
				aforoTipoE: [ // 85% 
					{ plazo: 10, porcentaje: 1.6387},
					{ plazo: 15, porcentaje: 2.2947 },
					{ plazo: 20, porcentaje: 2.8542 },
				],
				aforoTipoF: [ // 90% 
					{ plazo: 10, porcentaje: 1.9051 },
					{ plazo: 15, porcentaje: 2.6924 },
					{ plazo: 20, porcentaje: 3.4094 },
				],
			},

        caso: {},
        favoritos: [],
        cotizaData: [],
        id: '',
        mostrarInfoSolicitante: false,
        mostrarFavoritos: true,
        nombre: '',
        segundoNombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        telefono: '',
        email: '',
        emailEjecutivo: '',
        mensajeModal: 'Correo enviado',
        nuevaCotizacion: false,
        icono: '',
        columnasSelected: [],
        enganche: '',
        broker: '',
        solicitanteNombre:'',
        idCaso: '',
        amortizacion: '',
        ssvCofi: '',
        saldoInfo: '',
        PrecalificacionTipo: ''
      }
    },
    mounted() {
      if (localStorage.getItem('vistaNuevaCotizacion')) {
        this.nuevaCotizacion = true
      }
      this.id = this.$route.params.id
      this.getApikey()
      this.cotiza()


    },
    computed: {
      socData() {
        //return this.$store.state.socData
        return JSON.parse(localStorage.getItem('socData'))
      },
      emailState() {
        const regex = /^[-\w.%+]{1,64}@(?!socasesores)(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        const matches = regex.test(this.email);
        return this.email == null || matches ? true : false
      },
      celphoneState() {
        return this.telefono == null || this.telefono.length == 10 ? true : false
      },
      nameState() {
        return this.nombre == null || this.nombre.length >= 3 ? true : false
      },
      lastNameState() {
        return this.apellidoPaterno == null || this.apellidoPaterno.length > 2 ? true : false
      },
      validar() {
        return this.emailState && this.lastNameState && this.nameState && this.celphoneState
      }
    },
    methods: {
        // recibe las columnas del componente hijo y actualiza las del padre
        updateTable( cols ) {
            this.columnasSelected = cols;
            // console.log( this.columnasSelected );
        },
        // revisa si la columna esta seleccionada
        searchColTable( col ) {
            let isEnabled = this.columnasSelected.find(e => e.label == col);
            return isEnabled;
        },

        calculaFactorSantander(aforo, plazo, credito) {

          let aforoMultiplo = Math.ceil(aforo / 5) * 5; // obtiene multiplo de 5 mas cercano, si es multiplo exacto queda igual
          let factor;
          let montoAdicional = 0;
          
          if (aforoMultiplo >= 5 && aforoMultiplo <= 50) {
            factor = this.factorPorcentualSantander.aforoTipoA.find((item) => item.plazo == plazo);
          } else if (aforoMultiplo >= 55 && aforoMultiplo <= 70) {
            factor = this.factorPorcentualSantander.aforoTipoB.find((item) => item.plazo == plazo);
          }else if (aforoMultiplo == 75) {
            factor = this.factorPorcentualSantander.aforoTipoC.find((item) => item.plazo == plazo);
          }else if (aforoMultiplo == 80) {  
            factor = this.factorPorcentualSantander.aforoTipoD.find((item) => item.plazo == plazo);
          }else if (aforoMultiplo == 85) {
            factor = this.factorPorcentualSantander.aforoTipoE.find((item) => item.plazo == plazo);
          }else if (aforoMultiplo >= 90 && aforoMultiplo <= 100 ) {
            factor = this.factorPorcentualSantander.aforoTipoF.find((item) => item.plazo == plazo);
          }

          // verifica si encontro algun plazo valido
          if (!factor) {
            montoAdicional = 0;
            // console.log( 'No se encontro plazo', montoAdicional );
            return montoAdicional;
          }

          montoAdicional = ((factor.porcentaje / 100) * credito).toFixed(2);
          montoAdicional = Number(montoAdicional);

          // console.log( 'MontoData: ', factor.porcentaje, credito, plazo,  montoAdicional );
          return montoAdicional;
        },

      numberFormat(number = 0) {
        return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      regresarAcotizacion() {
        localStorage.setItem('regresoImprimirFavs', true)
        this.$router.push('/caso/' + this.id);
      },
      isNumber: function (evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      editaCaso() {
        //let self= this
        var objRequestRegistraCaso1 = {
          strApiKey: this.key,//'a13768e0b29183037ac1f3acb68871bc',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id: this.id,
            Solicitante: this.email,
            Destino: this.caso.destino,
            Broker: this.emailEjecutivo,
          }
        }
        this.$axios.post('/', objRequestRegistraCaso1, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then(
            response => {
              if (response.data.intEstatus == 0) {
                //alert('registrado')
                this.sendEmail()
                localStorage.removeItem('caso')
              } else {
                this.$vs.notify({
                  title: 'Ocurrio un error en registraCaso',
                  text: response.data.strError,
                  color: 'danger',
                  position: 'top-right'
                })
              }
            }
          ).catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right'
            })
          })
      },
      dameInformacion() {
        let self = this
        let objRequestDameSolicitante = {
          strApiKey: this.key,
          strMetodo: 'MisDatos',
        }
        this.$axios.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then(
            response => {
              if (response.data.intEstatus == 0) {
                let data = response.data.objContenido
                this.emailEjecutivo = data.EMail
                this.editaCaso()
              }
            }
          ).catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right'
            })
          })
      },
      registrarSolicitante() {
        let self = this
        /*this.$vs.loading({
          container: '#cotizacion',
          scale: 0.6
        })*/
        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.email,
            Nombres: this.nombre,
            SegundoNombre: this.segundoNombre,
            ApellidoPaterno: this.apellidoPaterno,
            ApellidoMaterno: this.apellidoMaterno,
            TelefonoCelular: this.telefono,
            FechaNacimiento: '1992-01-01',
            StateData: 0,
          }
        }

        this.$axios.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then(
            response => {
              if (response.data.intEstatus == 0) {
                this.dameInformacion()
              } else {
                this.$vs.notify({
                  title: 'Ocurrio un error en RegistraSolicitante',
                  text: response.data.strError,
                  color: 'danger',
                  position: 'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right'
            })
          })

      },
      regresar() {
        localStorage.setItem('regreso', true)
        this.mostrarInfoSolicitante = false
        this.mostrarFavoritos = true
        this.$router.push('/nuevaCotizacion');
      },
      mostrarForm() {
        this.mostrarInfoSolicitante = true
        this.mostrarFavoritos = false
      },
      sendEmail() {
        if (this.$store.state.AppActiveUser.TipoEjecutivoV3 != 'Inmo' && this.nuevaCotizacion) {
          //this.mostrarInfoSolicitante=true
          //this.mostrarFavoritos=false
          this.caso.solicitante = this.email
        }
        let self = this
        var objEmail = {
          to: this.caso.solicitante,
          case: this.id,
          token: this.key
        }
        axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/options-comparative.php', objEmail, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
          .then(
            response => {
              if (this.$store.state.AppActiveUser.TipoEjecutivoV3 != 'Inmo' && this.nuevaCotizacion && response.data.status == 201) {
                $('#modalTabla').modal('show');
                this.mensajeModal = 'Correo enviado'
                $('#icono').html('').html('<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16"><path style="color:#079DEF" d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>');

                setTimeout(() => $('#modalTabla').modal('hide'), 10000);
                $("#modalTabla").on('hidden.bs.modal', function () {
                  self.$router.push('/')
                });
              } else if (this.$store.state.AppActiveUser.TipoEjecutivoV3 != 'Inmo' && this.nuevaCotizacion && response.data.status == 301) {
                $('#modalTabla').modal('show')
                $('#icono').html('').html('<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/><path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/></svg>');
                $("#modalTabla").on('hidden.bs.modal', function () {
                  self.$router.push('/')
                });
                this.mensajeModal = 'Servicio de correo no disponible'
                setTimeout(() => $('#modalTabla').modal('hide'), 10000);
              }
              this.$vs.notify({
                title: 'Se envió correctamente el correo electrónico a',
                text: this.caso.solicitante,
                position: 'top-right'
              })
            }
          ).catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right'
            })
          })
      },
      prueba() {
        this.$router.push('/').catch(() => { })
      },
      dameCaso() {        
        let self = this
        var objRequestDameCaso = {
          strApiKey: this.key,
          strMetodo: 'DameCaso',
          objCaso: {
            Id: this.id
          }
        }
        this.$axios.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then(
            response => {
              if (response.data.intEstatus == 0) {
                let cotizacion = response.data.objContenido;
                this.caso = response.data.objContenido;
                this.caso.solicitante = cotizacion.Solicitante
                this.caso.destino = cotizacion.Destino
                this.caso.programa = cotizacion.Programas                
                this.caso.esquemas=cotizacion.Esquemas 
                this.enganche = this.caso.ValorInmueble - this.caso.MontoSolicitado 
                this.solicitanteNombre = this.caso.SolicitanteNombre
                this.caso.MontoSolicitado = this.caso.MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                this.caso.ValorInmueble = this.caso.ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                if (this.caso.Destino == 19 || this.caso.Destino == 25 || this.caso.Destino == 22 || this.caso.Destino == 33 || this.caso.Destino == 34) {
                  this.caso.SaldoInsoluto = this.caso.SaldoInsoluto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  this.caso.PagoMensualActual = this.caso.PagoMensualActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                if (this.caso.programa == 4) {
                  this.ssvCofi = this.caso.SubCuentaVivienda
                  this.saldoInfo = this.caso.CreditoInfonavit
                }
                this.favoritos = cotizacion.Favoritos                     
               /* this.favoritos.sort((a, b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
                this.favoritos[0].MejorPagoTotal = true
                this.favoritos.sort((a, b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
                this.favoritos[0].MejorTasa = true
                this.favoritos.sort((a, b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
                this.favoritos[0].MejorCat = true
                this.favoritos.sort((a, b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
                this.favoritos[0].MejorIngresoMinimo = true
                //this.favoritos.sort((a, b) => (a.GastosIniciales > b.GastosIniciales) ? 1 : ((b.GastosIniciales > a.GastosIniciales) ? -1 : 0));
                //this.favoritos[0].MejorGastosIniciales = true
                this.favoritos.sort((a, b) => (a.Mensualidad > b.Mensualidad) ? 1 : ((b.Mensualidad > a.Mensualidad) ? -1 : 0));
                this.favoritos[0].MejarMensualidad = true
                this.favoritos.sort((a, b) => (a.AhorroTotal > b.AhorroTotal) ? 1 : ((b.AhorroTotal > a.AhorroTotal) ? -1 : 0));
                this.favoritos[0].MejorAhorroTotal = true
                this.favoritos.sort((a, b) => (a.MejorMensualidad > b.MejorMensualidad) ? 1 : ((b.MejorMensualidad > a.MejorMensualidad) ? -1 : 0));
                this.favoritos[0].MejorMeMensualidad = true*/
                this.PrecalificacionTipo = cotizacion.PrecalificacionTipo                
                this.dameTablaAmortizacion()                    
              } else {
                this.$vs.notify({
                  title: 'Ocurrio un error en DameCaso',
                  text: response.data.strError,
                  color: 'danger',
                  position: 'top-right'
                })
              }
            }
          ).catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right'
            })
          })
      },
      getApikey() {
        if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
          this.$router.push('/login');
        } else {
          this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey;
          this.broker=JSON.parse(localStorage.getItem('userInfo')).EMail;
        }
      },
      print() {
        var is_chrome = Boolean(window.chrome);
        if (is_chrome) {
          setTimeout(function () {
            //window.print();
            $('.perfil').hide();
            $('.logo-position').hide();
            $('.btnocultaim').hide();
            window.print();

            //window.close();
            $('.btnocultaim').show();
            $('.perfil').show();
            $('.logo-position').show();


          }, 200);
        }
        else {
          //window.print();
          $('.perfil').hide();
          $('.logo-position').hide();
          window.print();
          //window.close();
          $('.perfil').show();
          $('.logo-position').show();
        }
      },
      cotiza() {

        let self = this
        let objRequestCotizaCaso = {
          strApiKey: this.key,
          strMetodo: 'Cotiza',
          objCaso: {
            Id: this.id,
          },
        }
        this.$axios
          .post('/', objRequestCotizaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
          .then((response) => {
            this.cotizaData = response.data.objContenido;
            this.dameCaso()
          })
          .catch(function (error) {
            self.$vs.notify({
              title: 'Ocurrio un error de sistema',
              text: error,
              color: 'danger',
              position: 'top-right',
            })
          })
      },
      descargarPDF(Favoritos){
        var objDatos ={      
          apiKey: this.key,
          idCaso : this.caso.Id,
          solicitante: this.solicitanteNombre,      
          valorInmueble : this.caso.ValorInmueble,
          montoCredito : this.caso.MontoSolicitado,
          enganche : this.enganche.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          esquemas: this.caso.esquemas,
          broker: this.broker
        }
        var favs = Favoritos.favoritos;
        var camposFavoritos = []
        const dataProductos = []

        // return console.log('favs :>> ', favs)

        for (let i = 0; i < favs.length; i++) {
          camposFavoritos.push(favs[i].Variante, favs[i].Banco, favs[i].Logo, favs[i].Mensualidad, favs[i].MejarMensualidad, favs[i].Plazo/12, favs[i].PagoTotal, favs[i].MejorPagoTotal, favs[i].Tasa, favs[i].MejorTasa, favs[i].Cat, favs[i].MejorCat, favs[i].IngresoMinimo, favs[i].MejorIngresoMinimo, favs[i].GastosIniciales,favs[i].MejorGastosIniciales)

          dataProductos.push(favs[i].Id)
        }

        // window.open(`https://cotizador.socasesores.com/emailComparaleProd/DescargarPDF_favoritos.php?objDatos=${ JSON.stringify(objDatos) }&camposFavoritos=${ JSON.stringify(camposFavoritos) }&dataProductos=${ JSON.stringify(dataProductos) }`, "Diseño Web", "width=1200, height=700")

        if (this.PrecalificacionTipo == 3) {
          location.href = `https://cotizador.socasesores.com/emailComparaleProd/favoritos.php?objDatos=${ JSON.stringify(objDatos) }&camposFavoritos=${ JSON.stringify(camposFavoritos) }&dataProductos=${ JSON.stringify(dataProductos) }`  
        }else{
          location.href = `https://cotizador.socasesores.com/emailComparaleProd/DescargarPDF_favoritos.php?objDatos=${ JSON.stringify(objDatos) }&camposFavoritos=${ JSON.stringify(camposFavoritos) }&dataProductos=${ JSON.stringify(dataProductos) }`
        }        
      },
    dameTablaAmortizacion(){
      for (let index = 0; index < this.favoritos.length; index++) {

        if(this.id>0){
          this.idCaso=this.id
        }

        let TablaPagosIon= ''
        let AmortizacionPdf=''
        let AmortizacionJson=''

        
          if(this.favoritos[index].BancoId==21){
            TablaPagosIon=true;
            AmortizacionPdf= this.favoritos[index].PdfAmortizacion
            AmortizacionJson= this.favoritos[index].JsonAmortizacion
          }

          let objRequestDameTablaPagos = {
            strApiKey: this.key,
            strMetodo: 'DameTablaPagos',
            objResultado: {
              Id:this.favoritos[index].Id,
              caso:this.id,
              caso:this.idCaso,
              IonAmortizacion:TablaPagosIon,
              pdfIon:AmortizacionPdf,
              jsonIon:AmortizacionJson,
              plazo: this.favoritos[index].Plazo,
              tasa: this.favoritos[index].Tasa
            }
          }

          this.$axios.post('/',objRequestDameTablaPagos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus != 0 ){
              this.$vs.notify({
                title:'Ocurrio un error en DameTablaPagos',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })            
            }else {
              this.amortizacion = response.data.objContenido            
              this.favoritos[index].Mensualidad = this.amortizacion[1].PagoMensual.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.favoritos[index].Tasa = this.amortizacion[1].Tasa
              //console.log(this.amortizacion[1].PagoMensual)

              var pagoTotal = 0;
              for (let i = 0; i < this.amortizacion.length; i++) {
                pagoTotal += this.amortizacion[i].PagoMensual;                
              }
              this.favoritos[index].PagoTotal = pagoTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")                                        
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
              
      }

                //console.log(this.favoritos)
                for (var a = 0; a < this.cotizaData.length; a++) {
                  for (var b = 0; b < this.favoritos.length; b++) {
                    if (this.cotizaData[a].Id == this.favoritos[b].Id) {
                      var ahoroMen = this.cotizaData[a].MejoraMensualidad
                      this.favoritos[b].MejorMensualidad = ahoroMen
                    }
                  }
                }
                // console.log( 'FAVORITOS', this.favoritos );

                // monto adicional de santander
                let factorAdicionalSantander = 0;

                for (var i = 0; i < this.favoritos.length; i++) {

                  if (this.favoritos[i].DobleTablaAmortizacion) {
                    
                  }

                   /////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
										if(this.favoritos[i].Variante==4 || this.favoritos[i].Variante==171){
                        this.favoritos[i].GastosNotariales = 0
                    }else if(this.favoritos[i].Variante==182 || this.favoritos[i].Variante==183 || this.favoritos[i].Variante==190 || this.favoritos[i].Variante==32 || this.favoritos[i].Variante==189 || this.favoritos[i].Variante==11 || this.favoritos[i].Variante==39 || this.favoritos[i].Variante==17 || this.favoritos[i].Variante==23 || this.favoritos[i].Variante==24 || this.favoritos[i].Variante==96 || this.favoritos[i].Variante==109 || this.favoritos[i].Variante==144 || this.favoritos[i].Variante==167 || this.favoritos[i].Variante==168 || this.favoritos[i].Variante==170 || this.favoritos[i].Variante==66 || this.favoritos[i].Variante==67 || this.favoritos[i].Variante==71 || this.favoritos[i].Variante==72 || this.favoritos[i].Variante==85 || this.favoritos[i].Variante==9 || this.favoritos[i].Variante==10 || this.favoritos[i].Variante==97 || this.favoritos[i].Variante==98 || this.favoritos[i].Variante==102 || this.favoritos[i].Variante==103){

											this.favoritos[i].GastosNotariales= ( (this.caso.GastoNotarial * this.favoritos[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.MontoSolicitado.replace(/,/g,""))
									
									
                  	}else if(this.favoritos[i].Variante==19 || this.favoritos[i].Variante==20 || this.favoritos[i].Variante==25  || this.favoritos[i].Variante==163){////Scotiabank tiene promo de 20% de descuento
                       
											this.favoritos[i].GastosNotariales= (( (this.caso.GastoNotarial * this.favoritos[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.ValorInmueble.replace(/,/g,"")))
                      
										} else{
                      
                        // TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
                        // TODO: para pruebas ( 156, 157 )
                        factorAdicionalSantander = 0;

                        if(this.favoritos[i].Variante == 191 || this.favoritos[i].Variante == 192) {//PROD
                        //if(this.favoritos[i].Variante == 174 || this.favoritos[i].Variante == 175) {//DEV
                          
                          factorAdicionalSantander = this.calculaFactorSantander(this.favoritos[i].Aforo, (this.favoritos[i].Plazo / 12), this.favoritos[i].Monto);
                        } 

                        this.favoritos[i].GastosNotariales = ( (this.caso.GastoNotarial * this.favoritos[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.ValorInmueble.replace(/,/g,""))
                    }
                  
                  this.favoritos[i].GastosIniciales = (this.favoritos[i].Avaluo + this.favoritos[i].ComisionApertura + this.favoritos[i].GastosNotariales + factorAdicionalSantander + this.favoritos[i].GastosInicialesAmortizacion2)
                  this.favoritos[i].Monto = this.favoritos[i].Monto
                  this.favoritos[i].Mensualidad = this.favoritos[i].Mensualidad
                  this.favoritos[i].Cat = this.favoritos[i].Cat
                  this.favoritos[i].IngresoMinimo = this.favoritos[i].IngresoMinimo
                  this.favoritos[i].PagoTotal = this.favoritos[i].PagoTotal
                  this.favoritos[i].MensualidadInicial = this.favoritos[i].MensualidadInicial

                }

                //this.favoritos = cotizacion.Favoritos                     
                this.favoritos.sort((a, b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
                this.favoritos[0].MejorPagoTotal = true
                this.favoritos.sort((a, b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
                this.favoritos[0].MejorTasa = true
                this.favoritos.sort((a, b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
                this.favoritos[0].MejorCat = true
                this.favoritos.sort((a, b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
                this.favoritos[0].MejorIngresoMinimo = true
                //this.favoritos.sort((a, b) => (a.GastosIniciales > b.GastosIniciales) ? 1 : ((b.GastosIniciales > a.GastosIniciales) ? -1 : 0));
                //this.favoritos[0].MejorGastosIniciales = true
                this.favoritos.sort((a, b) => (a.Mensualidad > b.Mensualidad) ? 1 : ((b.Mensualidad > a.Mensualidad) ? -1 : 0));
                this.favoritos[0].MejarMensualidad = true
                this.favoritos.sort((a, b) => (a.AhorroTotal > b.AhorroTotal) ? 1 : ((b.AhorroTotal > a.AhorroTotal) ? -1 : 0));
                this.favoritos[0].MejorAhorroTotal = true
                this.favoritos.sort((a, b) => (a.MejorMensualidad > b.MejorMensualidad) ? 1 : ((b.MejorMensualidad > a.MejorMensualidad) ? -1 : 0));
                this.favoritos[0].MejorMeMensualidad = true
                this.favoritos.sort((a, b) => (a.GastosIniciales > b.GastosIniciales) ? 1 : ((b.GastosIniciales > a.GastosIniciales) ? -1 : 0));
                this.favoritos[0].MejorGastosIniciales = true

                this.favoritos.sort((a, b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0));

                for (var i = 0; i < this.favoritos.length; i++) {
                  this.favoritos[i].GastosIniciales = this.favoritos[i].GastosIniciales.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  this.favoritos[i].Monto = this.favoritos[i].Monto.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  this.favoritos[i].Mensualidad = this.favoritos[i].Mensualidad.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  this.favoritos[i].Cat = this.favoritos[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  this.favoritos[i].IngresoMinimo = this.favoritos[i].IngresoMinimo.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  this.favoritos[i].PagoTotal = this.favoritos[i].PagoTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  this.favoritos[i].MensualidadInicial = this.favoritos[i].MensualidadInicial.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }

          //gastos iniciales para Cofinanciamiento infonavit
          if (this.caso.programa == 4 && this.caso.destino == 1) {
            for (var i = 0; i < this.favoritos.length; i++) {              
              //this.favoritos[i].GastosIniciales = (parseFloat(this.caso.ValorInmueble.replace(/,/g, '')) - parseFloat(this.caso.MontoSolicitado.replace(/,/g, '')) + parseFloat(this.favoritos[i].GastosIniciales.replace(/,/g, '')) - (this.ssvCofi + this.saldoInfo)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              this.favoritos[i].GastosIniciales = (parseFloat(this.caso.ValorInmueble.replace(/,/g, '')) - parseFloat(this.caso.MontoSolicitado.replace(/,/g, '')) + parseFloat(this.favoritos[i].GastosIniciales.replace(/,/g, '')) - (this.ssvCofi + this.saldoInfo)).toFixed(2)
              this.favoritos[i].GastosIniciales = this.favoritos[i].GastosIniciales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')              
            }
          }
      
    },

    }
  }
</script>

<style lang="css" scoped>
  .logo {
    width: 120px;
  }

  .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
    padding: 0.75rem 2rem;
    color: #fff !important;
    background: #006D4E !important;
  }

  .feather-icon {
    width: 15px;
  }

  p {
    margin-left: 1%;
    margin-right: 1%;
    color: #ccc;
  }

  .bankName {
    display: none;
  }

  .vs-con-table .vs-con-tbody {
    width: 100%;
    overflow: hidden;
  }

  @media screen and (max-width:820px) {
    .producto {
      display: inline-block;
    }
  }

  @media print {
    .font-imp {
      font-size: 12px;
    }

    img {
      display: block;
    }

    .bankName {
      display: none;
    }

    .tabla-resumen {
      width: 100%;
      margin: 20px 0px;
    }

    .vs-con-table {
      margin: 0px;
    }


    .logo {
      width: 70px;
      height: auto;
    }


    div#botonPrint {
      display: none !important;
    }

    .card-resumen {
      padding-top: 55px;
    }

    .producto {
      font-size: 5px;
    }

    .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
      padding: 3px;
      font-size: 11px !important;
    }

  }

  @page {
    size: landscape;
  }
</style>